<template>
  <div>
    <v-container grid-list-xl>
      <app-card
        heading="Wachtwoord wijzigen"
        col-classes="xl12 lg12 md12 sm12 xs12"
        custom-classes="mb-0">
        <v-form
          ref="changePasswordForm"
          v-model="valid"
          class="mb-4 mt-4">
          <v-text-field
            v-model="oldPassword"
            :rules="passwordRules"
            type="password"
            label="Oud wachtwoord"
            required
          />
          <v-text-field
            v-model="newPassword"
            :rules="passwordRules"
            type="password"
            label="Nieuw wachtwoord"
            required
          />
          <v-text-field
            v-model="newPasswordRepeat"
            :rules="passwordRules"
            type="password"
            label="Herhaal wachtwoord"
            required
          />
          <v-btn
            id="change-password-submit"
            :disabled="!valid"
            :loading="loading"
            class="mt-2"
            large
            color="pink"
            block
            @click="submit"><span class="white-14">Wachtwoord opslaan</span></v-btn>
        </v-form>
      </app-card>
    </v-container>
  </div>
</template>

<script>
import request from '../../helpers/request'

export default {
  mixins: [request],
  data () {
    return {
      loading: false,
      valid: true,
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      passwordRules: [v => !!v || 'Is verplicht']
    }
  },
  beforeMount () {

  },
  methods: {
    submit () {
      if (this.$refs.changePasswordForm.validate()) {
        let body = {
          data: {
            'password': this.newPassword,
            'passwordRepeat': this.newPasswordRepeat,
            'oldPassword': this.oldPassword
          }
        }

        this.request('POST', '/me/reset-password', body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Wachtwoord ingesteld'
          })

          this.oldPassword = ''
          this.newPassword = ''
          this.newPasswordRepeat = ''
          this.$refs.changePasswordForm.reset()
        }, (loader) => { this.loading = loader })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
